import React from "react";
import { Link } from "react-router-dom";
import "./AuthFooter.css";

const AuthFooter = () => {
  return (
    <header className="auth-footer">
      <p>Copyright © Truth ARC. All Rights Reserved.</p>
    </header>
  );
};

export default AuthFooter;
