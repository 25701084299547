import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { UtilityContext } from "../contexts/UtilityContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./MyAccount.css";

const MyAccountPage = () => {
  const { isLoggedIn, logout, user } = useContext(AuthContext);
  const { maxLength, getHighestProbability, formatTime, formatTimeVerbose } =
    useContext(UtilityContext);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const [loading, setLoading] = useState(true); // 추가된 로딩 상태

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios
        .get("/api/user_info")
        .then((response) => {
          // 로그인 상태를 설정
          if (response.data.user) {
            setLoading(false);
          } else {
            setLoading(false);
            alert("로그인 후에 이용할 수 있습니다.");
            navigate("/signin");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
          alert("로그인 후에 이용할 수 있습니다.");
          navigate("/signin");
        });
    } else {
      setLoading(false);
      alert("로그인 후에 이용할 수 있습니다.");
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    if (!loading && isLoggedIn) {
      // 토큰 가져오기
      const token = localStorage.getItem("token");
      // 로그인된 사용자라면 서버에 데이터 요청
      axios
        .get("/api/get_my_results", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setData(response.data.results);
          const total = response.data.results.reduce(
            (sum, result) => sum + (result.videoLength || 0),
            0
          );
          setTotalLength(total);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return <p>Loading...</p>; // 로딩 중일 때 표시할 내용
  }
  const handleLogout = () => {
    navigate("/");
    logout();
  };
  if (!isLoggedIn) {
    return null; // 로그인되지 않은 상태에서 아무것도 표시하지 않음
  }

  const remainingLength = maxLength - totalLength;

  return (
    <div className="myaccount">
      <h1>My Account</h1>
      <div className="myaccount-info">
        <p>Email: {user.username}</p>
        <p>Password: **********</p>
      </div>
      <div className="myaccount-logout">
        <button onClick={handleLogout}>Sign Out</button>
      </div>
      <h2>Usage</h2>
      <p>
        <b>{formatTimeVerbose(remainingLength)}</b> (Remaining Usage) /{" "}
        {formatTimeVerbose(maxLength)} (Free Usage Allocation)
      </p>
      {data ? (
         <div className="myaccount-table-container">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Length</th>
              <th>Title</th>
              <th>Status</th>
          
              <th>Result</th>
            </tr>
          </thead>
          {data.length > 0 ? (
            <tbody>
              {data.map((item) => (
                <tr key={item._id}>
                  <td>{item.analysisDateTime}</td>
                  <td>{formatTime(item.videoLength)}</td>
                  <td className="title">{item.videoTitle}</td>
                  <td>{item.analysisStatus}</td>
                  <td>{getHighestProbability(item)}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
            <td colSpan="5" className="table-none">No data found</td>
          </tr>
            </tbody>
          )}
        </table></div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MyAccountPage;
