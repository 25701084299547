import React, { createContext } from "react";

export const UtilityContext = createContext();

export const UtilityProvider = ({ children }) => {
  const maxLength = 1800; // 최대 사용량

  const getHighestProbability = (item) => {
    const probabilities = {
      Conservative: item.conservativeProb,
      Neutral: item.neutralProb,
      Progressive: item.progressiveProb,
    };
    const highestKey = Object.keys(probabilities).reduce((a, b) =>
      probabilities[a] > probabilities[b] ? a : b
    );
    return `${highestKey} ${probabilities[highestKey]}%`;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const formatTimeVerbose = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minutesStr = minutes > 0 ? `${minutes} min` : "";
    const secondsStr = remainingSeconds > 0 ? `${remainingSeconds} sec` : "";
    return `${minutesStr} ${secondsStr}`.trim();
  };

  return (
    <UtilityContext.Provider
      value={{
        maxLength,
        getHighestProbability,
        formatTime,
        formatTimeVerbose,
      }}
    >
      {children}
    </UtilityContext.Provider>
  );
};
