import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return <footer className="footer">
     <a href="/"><img className="logo-img" src="/logo.png" alt="Logo" /></a>
    <p>Copyright © Truth ARC. All Rights Reserved.</p>
  </footer>;
};

export default Footer;
