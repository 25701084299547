import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import "./Header.css";

const Header = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  // 메뉴가 열렸을 때 배경 스크롤을 비활성화하는 useEffect
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // 컴포넌트가 언마운트될 때 원래 상태로 복원
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuOpen]);

  return (
    <header className="header">
      <div className="header-right">
        <div className="logo">
          <a href="/">
            <img className="logo-img" src="/logo.png" alt="Logo" />
          </a>
        </div>
        <nav className={`header-nav ${menuOpen ? "open" : ""}`}>
          <Link to="/" className="nav-item" onClick={closeMenu}>
            Home
          </Link>
          <Link to="/analyze" className="nav-item" onClick={closeMenu}>
            Analyze
          </Link>
          <Link to="/discussions" className="nav-item" onClick={closeMenu}>
            Discussion
          </Link>
          {/* 모바일 메뉴가 열렸을 때 보이는 회원가입/로그인 버튼 */}
          {menuOpen && (
            <div className="header-btn-mobile">
              {isLoggedIn ? (
                <Link to="/myaccount" onClick={closeMenu}>My Account</Link>
              ) : (
                <>
                  <Link to="/signup" onClick={closeMenu}>Sign Up</Link>
                  <Link to="/signin" onClick={closeMenu}>Sign In</Link>
                </>
              )}
            </div>
          )}
        </nav>
      </div>
      {/* 데스크톱에서만 보이는 회원가입/로그인 버튼 */}
      <div className="header-btn">
        {isLoggedIn ? (
          <Link to="/myaccount">My Account</Link>
        ) : (
          <>
            <Link to="/signup">Sign Up</Link>
            <Link to="/signin">Sign In</Link>
          </>
        )}
      </div>
      {/* 햄버거 메뉴 아이콘 */}
      <div className="hamburger-icon" onClick={toggleMenu}>
        ☰
      </div>
      {/* 닫기 버튼 */}
      {menuOpen && <div className="close-icon" onClick={toggleMenu}>×</div>}
    </header>
  );
};

export default Header;
