import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UtilityContext } from "../contexts/UtilityContext";
import BarGraph from "../components/common/BarGraph";
import axios from "axios";
import "./DiscussionsPage.css";

const DiscussionsPage = () => {
  const { getHighestProbability } = useContext(UtilityContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(1);
  }, []);
  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get("/api/get_results", {
        params: { page, per_page: 12 },
      });
      setData((prevData) => [...prevData, ...response.data.results]);
      setHasMore(response.data.has_more);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const getResultColor = (item) => {
    const result = getHighestProbability(item);
    console.log(result)
    if (result.includes("Progressive")) return "progressive-color";
    if (result.includes("Conservative")) return "conservative-color";
    if (result.includes("Neutral")) return "neutral-color";
    return "";
  };
  const handleClick = (id) => {
    navigate(`/discussion/${id}`);
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage);
  };

  return (
    <div className="discussions">
      <h1>Discussion</h1>
      <p>
        Here, you'll find the results of videos analyzed by other users. You can
        share your opinions and discuss the content and analysis results with
        others.
      </p>
      {data.length > 0 ? (
        <div className="video-container">
          {data.map((item) => (
            <div
              key={item._id}
              className="video-item"
              onClick={() => handleClick(item._id)}
            >
              <img
                src={item.videoImage}
                alt={item.videoTitle}
                className="video-image"
              />
              <h3>{item.videoTitle}</h3>
              <p className={getResultColor(item)}>{getHighestProbability(item)}</p>
              <BarGraph
                percentages={[
                  item.progressiveProb,
                  item.neutralProb,
                  item.conservativeProb,
                ]}
                height={10}
              />
            </div>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {hasMore && !loading && (
        <button onClick={handleLoadMore} className="load-more-button">
           More videos
        </button>
      )}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default DiscussionsPage;
