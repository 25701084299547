import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";
import { UtilityContext } from "../contexts/UtilityContext";
import "./HomePage.css";
import BarGraph from "../components/common/BarGraph";

const HomePage = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { maxLength, formatTimeVerbose } = useContext(UtilityContext);
  const { getHighestProbability } = useContext(UtilityContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analyzeloading, setAnalyzeloading] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [error, setError] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(1);
    if (isLoggedIn) {
      fetchTotalLength();
    }
  }, [isLoggedIn]);

  const fetchTotalLength = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/total_video_length", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalLength(response.data.totalLength);
    } catch (err) {
      console.error("Failed to fetch total length", err);
    }
  };

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get("/api/get_results", {
        params: { page, per_page: 4 },
      });
      setData(response.data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const getResultColor = (item) => {
    const result = getHighestProbability(item);
    console.log(result)
    if (result.includes("Progressive")) return "progressive-color";
    if (result.includes("Conservative")) return "conservative-color";
    if (result.includes("Neutral")) return "neutral-color";
    return "";
  };
  const handleClick = (id) => {
    navigate(`/discussion/${id}`);
  };

  const handleChange = (e) => {
    setVideoURL(e.target.value);
  };

  const extractVideoID = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === "youtu.be") {
        return urlObj.pathname.slice(1);
      }
      return urlObj.searchParams.get("v");
    } catch (e) {
      return null;
    }
  };

  const isValidYouTubeURL = (url) => {
    const videoID = extractVideoID(url);
    return videoID && videoID.length === 11;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const videoID = extractVideoID(videoURL);
    if (!isValidYouTubeURL(videoURL)) {
      setError("Please enter a valid URL.");
      return;
    }
    setAnalyzeloading(true);
    setError(null);
    const cleanURL = `https://www.youtube.com/watch?v=${videoID}`;
    try {
      const token = localStorage.getItem("token");
      console.log("전송하는 URL:", cleanURL);
      const response = await axios.post(
        "/api/analyze_results",
        { videoURL: cleanURL },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchTotalLength();
      navigate(`/discussion/${response.data.result._id}`);
    } catch (err) {
      if (err.response.data.msg === "Already existed") {
        navigate(`/discussion/${err.response.data._id}`);
        return;
      }
      setError(err.response.data.msg);
    } finally {
      setAnalyzeloading(false);
    }
  };
  const goToSignIn = () => {
    navigate("/signin");
  };
  const remainingLength = maxLength - totalLength;
  const placeholderMessage = !isLoggedIn
    ? "You can access this after signing in."
    : remainingLength <= 0
    ? "There is no remaining usage time."
    : "Enter the link to the YouTube video.";

  return (
    <div className={`container ${analyzeloading ? "loading" : ""}`}>
      {analyzeloading && (
        <div className="loader-overlay">
          <div className="loader">
            <p>
              This task may take several minutes to complete. <br></br>You can
              leave the window, and the analysis will continue. <br></br>Once
              the analysis is complete, you can check the results on your My
              Account page or the Discussion page.
            </p>
            <TailSpin color="#00BFFF" height={80} width={80} />
          </div>
        </div>
      )}
      <div className="home">
        <div className="home-hero">
          <div className="container">
            <h1>Is This Video Politically Biased?</h1>
            <p>
              Quickly and easily find out if your YouTube video is politically
              biased.
            </p>
            {!isLoggedIn ? (
              ""
            ) : (
              <div className="home-note">
                <p>
                  Note: The length of a video cannot exceed{" "}
                  <b>
                    <u>10 min.</u>
                  </b>
                </p>
                <p>
                  Your remaining usage time:{" "}
                  <b>
                    <u>{formatTimeVerbose(remainingLength)}</u>
                  </b>{" "}
                  <a href="/myaccount">View More</a>
                </p>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={videoURL}
                onChange={handleChange}
                placeholder={placeholderMessage}
                disabled={!isLoggedIn || remainingLength <= 0}
              />
              {!isLoggedIn ? (
                <button onClick={goToSignIn} disabled={remainingLength <= 0}>
                  Sign In and Analyze
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={!isLoggedIn || remainingLength <= 0}
                >
                  Analyze
                </button>
              )}
            </form>
            {error && <p className="error-msg" style={{ color: "red" }}>{error}</p>}
          </div>
        </div>
        <div className="videos">
          <h2>Preview Analysis Results</h2>
          {data.length > 0 ? (
            <div className="video-container">
              {data.map((item) => (
                <div
                  key={item._id}
                  className="video-item"
                  onClick={() => handleClick(item._id)}
                >
                  <img
                    src={item.videoImage}
                    alt={item.videoTitle}
                    className="video-image"
                  />
                  <h3>{item.videoTitle}</h3>
                  <p className={getResultColor(item)}>{getHighestProbability(item)}</p>
                  <BarGraph
                    percentages={[
                      item.progressiveProb,
                      item.neutralProb,
                      item.conservativeProb,
                    ]}
                    height={10}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}
          {loading && <p>Loading...</p>}
        </div>
        <div className="introduce">
          <h2>Introduction to TruthArc Website</h2>
          <p> TruthArc is an innovative platform that utilizes artificial intelligence (AI) to detect and analyze political bias in video content. The website is designed to help users evaluate the political leanings of various video materials such as news, documentaries, films, and TV shows. It provides valuable insights for media consumers to understand the underlying political messages or biases in the content they are watching.
          <h3>Purpose of the Website</h3>
          <p>TruthArc aims to enhance transparency and understanding of political bias in video content. By offering AI-powered analysis tools, the platform reveals hidden political messages or prejudices within the media. This allows consumers to critically analyze and engage with media more thoughtfully. Ultimately, the goal is to improve media literacy and prevent confusion or misconceptions that may arise from biased information. </p>
          <h3>Key Features</h3>
          <ul>
            <li>
            AI-Based Political Bias Detection: The core feature of TruthArc is its AI system that analyzes video content to identify political biases. The AI processes text, speech, and image data to detect tendencies toward specific political ideologies, providing users with a visualized report of its findings.
            </li>
            <li>
            Bias Score: Each video is assigned a bias score, reflecting how strongly it leans toward left-wing, right-wing, or neutral political positions. This scoring system allows viewers to quickly assess the political tone of the content.
            </li>
            <li>
            Recommended Content: TruthArc offers recommendations for content that has minimal bias or presents a balanced range of perspectives, encouraging users to consume media that reflects diverse viewpoints.
            </li>
            <li>
            User Engagement: Users can provide feedback on the analysis results, request their own video analyses, or share their thoughts with the community. This interactive feature fosters a rich environment where various opinions and viewpoints can be exchanged.
            </li>
          </ul>
          <h3>Content Provided</h3>
          In addition to AI-generated analysis reports, TruthArc offers news analysis, video reviews, and blog posts on political neutrality in media. The platform also provides educational materials and guides to help users improve their ability to analyze and critically engage with video content.
          <h3>Important Note: Video Length Limitation</h3>
          TruthArc currently does not analyze videos longer than 15 minutes. For longer video materials, users are encouraged to submit key segments for analysis.
          <h3>Conclusion</h3>
          TruthArc is a website that leverages AI technology to analyze the political bias of video content, helping consumers make informed media choices. With its political bias detection, user interaction features, and objective content, it contributes to a more balanced and fair media consumption landscape.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
