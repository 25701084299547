import React from "react";
import { Link } from "react-router-dom";
import "./AuthHeader.css";

const AuthHeader = () => {
  return (
    <header className="auth-header">
      <div className="logo">
        <a href="/">
          <img src="/logo.png" alt="Logo" />
        </a>
      </div>
    </header>
  );
};

export default AuthHeader;
