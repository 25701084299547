import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";
import { UtilityContext } from "../contexts/UtilityContext";
import "./DiscussionPage.css";
import BarGraph from "../components/common/BarGraph";
import { formatText } from "../components/common/formatText";

const DiscussionPage = () => {
  const { discussionId } = useParams();
  const { isLoggedIn, user } = useContext(AuthContext);
  const { getHighestProbability } = useContext(UtilityContext);
  const [data, setData] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commentPage, setCommentPage] = useState(1);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [newComment, setNewComment] = useState("");
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState("");
  const imageRef = useRef(null);
  const [activeTab, setActiveTab] = useState("Tab1");
  const [barGraphHeight, setBarGraphHeight] = useState(30);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

 // 화면 크기에 따라 BarGraph의 높이 설정
 useEffect(() => {
  const updateBarGraphHeight = () => {
    setBarGraphHeight(window.innerWidth <= 768 ? 15 : 30);
  };

  // 초기값 설정
  updateBarGraphHeight();

  // 화면 크기 변경 감지 이벤트 추가
  window.addEventListener("resize", updateBarGraphHeight);

  // 이벤트 해제
  return () => window.removeEventListener("resize", updateBarGraphHeight);
}, []);
// 결과에 따른 색상 스타일 결정 함수
const getResultColor = () => {
  const result = getHighestProbability(data);
  if (result.includes("Progressive")) return "progressive-color";
  if (result.includes("Conservative")) return "conservative-color";
  if (result.includes("Neutral")) return "neutral-color";
  return "";
};
  useEffect(() => {
    fetchData(1);
  }, [discussionId]);

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/get_result/${discussionId}`);
      setData(response.data.result);

      const commentsResponse = await axios.get(
        `/api/get_comments/${discussionId}`,
        {
          params: { page, per_page: 5 },
        }
      );
      setComments(commentsResponse.data.comments);
      setHasMoreComments(commentsResponse.data.has_more);
      setCommentPage(page);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMoreComments = async () => {
    const nextPage = commentPage + 1;
    try {
      const commentsResponse = await axios.get(
        `/api/get_comments/${discussionId}`,
        {
          params: { page: nextPage, per_page: 5 },
        }
      );
      setComments((prevComments) => [
        ...prevComments,
        ...commentsResponse.data.comments,
      ]);
      setHasMoreComments(commentsResponse.data.has_more);
      setCommentPage(nextPage);
    } catch (error) {
      console.error("Error loading more comments:", error);
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!newComment.trim()) {
      alert("Please write the content in the comment.");
      return;
    }
    try {
      await axios.post(
        `/api/add_comment/${discussionId}`,
        {
          content: newComment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewComment("");
      fetchData(1); // 새로운 댓글을 추가한 후 첫 페이지 데이터 갱신
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleEditCommentChange = (e) => {
    setEditCommentContent(e.target.value);
  };

  const handleEditCommentSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!editCommentContent.trim()) return;
    try {
      await axios.put(
        `/api/update_comment/${discussionId}/${editCommentId}`,
        {
          content: editCommentContent,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditCommentId(null);
      setEditCommentContent("");
      fetchData(commentPage); // 댓글을 수정한 후 데이터 갱신
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(`/api/delete_comment/${discussionId}/${commentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchData(1); // 댓글을 삭제한 후 첫 페이지 데이터 갱신
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditClick = (commentId, content) => {
    setEditCommentId(commentId);
    setEditCommentContent(content);
  };

  const formatDate = (dateString) => {
    // 입력된 UTC 시간 문자열을 Date 객체로 변환
    const utcDate = new Date(dateString);

    // 로컬 시간으로 변환된 Date 객체 생성
    const localDate = new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
      utcDate.getUTCHours(),
      utcDate.getUTCMinutes(),
      utcDate.getUTCSeconds()
    );

    const dateOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12시간 형식 사용하여 AM/PM 출력
    };

    const formattedDate = localDate
      .toLocaleDateString("ko-KR", dateOptions)
      .replace(/\./g, ".")
      .trim();
    const formattedTime = localDate
      .toLocaleTimeString("en-US", timeOptions)
      .trim();

    return `${formattedDate} ${formattedTime}`;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  return (
    <div className="discussion">
      {data ? (
        <div className="container">
          <h1>{data.videoTitle}</h1>
          <p>
            Analyzed by: <b>{data.analyst}</b> | Analysis Date:{" "}
            <b>{data.analysisDateTime}</b> | Original Video:{" "}
            <b>{data.videoChannel}</b>
          </p>
         
          <iframe
            src={data.videoURL.replace("watch?v=", "embed/")}
            title={data.videoTitle}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <h2 className={getResultColor()}>{getHighestProbability(data)}</h2>
          <p>
            <span className="p-red">Progressive {data.progressiveProb}%</span> | <span className="n-white">Neutral {data.neutralProb}%</span> | 
            <span className="c-blue"> Conservative {data.conservativeProb}%</span>
          </p>
          <BarGraph
            percentages={[
              data.progressiveProb,
              data.neutralProb,
              data.conservativeProb,
            ]}
            height={barGraphHeight}
          />
          <div className="tab">
            <button
              className={`tablinks ${activeTab === "Tab1" ? "active" : ""}`}
              onClick={() => handleTabClick("Tab1")}
            >
              Analysis Basis
            </button>
            <button
              className={`tablinks ${activeTab === "Tab2" ? "active" : ""}`}
              onClick={() => handleTabClick("Tab2")}
            >
              Scripts
            </button>
          </div>
          {activeTab === "Tab1" && (
            <div id="Tab1" className="tab-content active">
              <div
                dangerouslySetInnerHTML={{
                  __html: formatText(data.analysisBasis),
                }}
              />
            </div>
          )}

          {activeTab === "Tab2" && (
            <div id="Tab2" className="tab-content active">
              <div
                dangerouslySetInnerHTML={{
                  __html: formatText(data.analysisScript),
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <p>No data found</p>
      )}
      <div className="comments">
        <h2>Comments</h2>
        {isLoggedIn ? (
          <form onSubmit={handleCommentSubmit}>
            <b>
              <label>{user.username}</label>
            </b>
            <textarea
              value={newComment}
              onChange={handleCommentChange}
              placeholder="|Share your thoughts on the video’s analysis. Please be respectful and constructive."
              rows="4"
              cols="50"
            />
            <div className="button">
              <button type="submit">Comment</button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleCommentSubmit}>
            <textarea
              placeholder="You can access this after signing in."
              rows="4"
              cols="50"
            />
            <div className="button">
              <button type="submit" disabled>
                Comment
              </button>
            </div>
          </form>
        )}
        {comments.length > 0 ? (
          <ul>
            {comments.map((comment) => (
              <li key={comment._id}>
                {editCommentId === comment._id ? (
                  <form className="edit-comment" onSubmit={handleEditCommentSubmit}>
                    <textarea
                      value={editCommentContent}
                      onChange={handleEditCommentChange}
                      rows="4"
                      cols="50"
                    />
                    <br />
                    <div className="comments-edit-btn">
                      <button onClick={() => setEditCommentId(null)}>
                        Cancel
                      </button>
                      <button type="submit">Comment</button>
                    </div>
                  </form>
                ) : (
                  <>
                    <div className="comments-meta">
                      <div className="comments-meta-left">
                        <b>
                          <small>{comment.author}</small>
                        </b>
                        <small className="comment-date">
                          {formatDate(comment.createdAt)}
                          {comment.updatedAt && ` (edited)`}
                        </small>
                      </div>
                      <div>
                        {isLoggedIn &&
                          user &&
                          user.username === comment.author && (
                            <>
                              <button
                                onClick={() =>
                                  handleEditClick(comment._id, comment.content)
                                }
                                className="control-btn"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeleteComment(comment._id)}
                                className="control-btn"
                              >
                                Delete
                              </button>
                            </>
                          )}
                      </div>
                    </div>
                    <p className="comment-content">{comment.content}</p>
                  </>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className="comments-none">No comments found</p>
        )}
        {hasMoreComments && (
          <button onClick={handleLoadMoreComments} className="comment-more-btn">More Comments</button>
        )}
      </div>
    </div>
  );
};

export default DiscussionPage;
