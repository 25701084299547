import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./SignInPage.css";

const SignInPage = () => {
  const { isLoggedIn, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // 추가된 로딩 상태

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        try {
          const response = await axios.get("/api/user_info");
          if (response.data.user) {
            setAuth(token);
            alert("이미 로그인하였습니다.");
            navigate("/myaccount");
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    checkAuth();
  }, [navigate, setAuth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/login", {
        username: email,
        password: password,
      });
      setAuth(response.data.access_token);
      navigate("/");
    } catch (error) {
      setError("Invalid username or password.");
    }
  };

  if (loading) {
    return <p>Loading...</p>; // 로딩 중일 때 표시할 내용
  }

  return (
    <div className="signin">
      <h2>Sign In</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Sign In</button>
        <p>
          Don't have a account? <a href="/signup">Sign Up</a>
        </p>
      </form>
    </div>
  );
};

export default SignInPage;
